@font-face {
    font-family: PingFangSC-Lighter;
    src: url("../assets/fonts/PingFangSC-Lighter-subset.woff2") format("woff2");
}

@font-face {
    font-family: PingFangSC-Light;
    src: url("../assets/fonts/PingFangSC-Light-subset.woff2") format("woff2");
}

@font-face {
    font-family: PingFangSC-Regular;
    src: url("../assets/fonts/PingFangSC-Regular-subset.woff2") format("woff2");
}

@font-face {
    font-family: PingFangSC-Medium;
    src: url("../assets/fonts/PingFangSC-Medium-subset.woff2") format("woff2");
}

* {
    margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFangSC-Regular, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Source Han Sans CN, sans-serif;
}

a {
    text-decoration: none;
}

h2,
h3 {
    font-family: PingFangSC-Medium;
    font-weight: 500;
}

p {
    font-family: PingFangSC-Light;
}

h2,
h3
p {
    text-align: center;
}

ul,
ol,
li {
    padding: 0;
    list-style: none;
}

header {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1176px;
    background-color: #1d2127;
}

.menu,
.sub-menu {
    display: flex;
    margin: 0 auto;
}

.menu {
    width: 1176px;
    align-items: center;
    justify-content: flex-end;
    user-select: none;
}

.logo {
    flex: 1 0 auto;
}

.menu-item {
    color: #fff;
    font-size: 16px;
    height: 60px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}

.menu-item a {
    color: inherit;
    text-decoration: none;
}

.sub-menu-title {
    cursor: pointer;
}

.sub-menu {
    width: 100px;
    height: 0;
    overflow: hidden;
    margin-left: -50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    top: 60px;
    left: 50%;
}

.menu-item:hover .sub-menu {
    overflow: visible;
    height: 85px;
}

.sub-menu::before {
    position: absolute;
    top: -20px;
    left: 40px;
    content: "";
    display: block;
    border: 10px solid #fff0;
    border-bottom-color: #fff;
}

.sub-menu-item {
    font-size: 14px;
    height: 20px;
    padding: 0 20px;
    color: #333;
    text-align: left;
}

.sub-menu-item+.sub-menu-item {
    margin-top: 15px;
}

.active,
.menu-item:hover {
    color: #0f7ac2;
}

.content {
    background-color: #fbfbfb;
    min-height: calc(100vh - 340px);
}

.banner {
    height: 425px;
    margin: -10px 0 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.card-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.bg-dark {
    background-color: #1d2127;
    color: #fff;
}

.more {
    width: 200px;
    height: 35px;
    color: #fff;
    display: block;
    font-size: 15px;
    line-height: 35px;
    text-align: center;
    background: #0f7ac2;
    border-radius: 18px;
}

footer {
    color: #8c8c8c;
    min-width: 1176px;
    width: 100%;
    height: 264px;
    padding-top: 60px;
    box-sizing: border-box;
    background-color: #1a1a1a;
}

.footer-wrapper {
    width: 1176px;
    margin: 0 auto;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
}

.footer-wrapper h3 {
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 60px;
    height: 60px;
}

.footer-wrapper li,
.footer-wrapper a {
    font-size: 14px;
    color: #8c8c8c;
}

.footer-wrapper li {
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
}

.wechat svg {
    color: #fff;
}

.wechat:hover svg {
    color: #0f7ac2;
}

.wechat+li {
    visibility: hidden;
    margin-top: 20px;
}

.wechat:hover+li {
    visibility: visible;
}
