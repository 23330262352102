.section {
    min-width: 1176px;
    min-height: 45vw;
    height: 100vh;
}
.center{
    text-align: center;
}
.banner {
    margin: 0;
    background-image: url('../assets/images/index_banner.png');
}

.section-title {
    flex: 2 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-title h2 {
    height: 65px;
    font-size: 45px;
    line-height: 65px;
}

.section-title p {
    height: 25px;
    font-size: 18px;
    line-height: 25px;
    margin-top: 10px;
}

.card-container {
    width: 1176px;
    flex: 0 0 455px;
}

.card {
    flex: 0 0 264px;
    padding: 0 35px;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
}

.card:hover {
    color: #fff;
    background-size: 100px;
    background-color: #0f7ac2;
}

.card .svg-box {
    height: 195px;
    padding-top: 65px;
    box-sizing: border-box;
}

.card svg {
    width: 95px;
    display: block;
    margin: 0 auto;
    fill: #0f7ac2;
    transition: width .5s;
}

.card:hover svg {
    width: 100px;
    fill: #fff;
}

.card h3 {
    font-size: 20px;
    color: #1a1a1a;
    margin-bottom: 35px;
}

.card p {
    font-size: 14px;
    color: #8c8c8c;
    line-height: 22px;
}

.card:hover h3,
.card:hover p {
    color: #fff;
}

.button-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.more {
    margin: 0 auto;
}